import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormGroup,
  Label,
  Button,
  Container,
  Row,
  Col,
  FormText,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import "./registrationForm.scss";
import CustomDatePicker from "../../sharedComponents/customDatePicker/CustomDatePicker";
import CustomSelect from "../../sharedComponents/customSelect/CustomSelect";
import swal from "sweetalert";
import { addDoc, collection, db } from "../../config/firebase";
import { showToast } from "../../sharedComponents/toast/showTaost";
import emailJs from "@emailjs/browser";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .required("First name is required"),
  middle_name: Yup.string().matches(
    /^[A-Za-z\s]*$/,
    "Only alphabets are allowed"
  ),
  last_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .required("Last name is required"),
  birth_date: Yup.date()
    .max(new Date(), "Birth date cannot be in the future")
    .required("Birth date is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
    .required("Mobile number is required"),
  course: Yup.string().required("Please select a course"),
  is_working: Yup.boolean(),
  company_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .when("is_working", {
      is: true,
      then: Yup.string().required("Company Name is required"),
    }),
  college_name: Yup.string().matches(
    /^[A-Za-z\s]+$/,
    "Only alphabets are allowed"
  ),
});

const initialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  birth_date: "",
  gender: "",
  email: "",
  mobile: "",
  course: "",
  is_working_professional: false,
  company_name: "",
  training_reason: "",
  college_name: "",
};

const courseList = [
  { value: "web_dev", label: "Web Development" },
  { value: "complete_java", label: "Complete Java Development" },
  { value: "full_stack_dev", label: "Full Stack Development" },
  { value: "python_ml", label: "Python & Machine Learning" },
  { value: "c_cpp", label: "C & C++" },
  { value: "java", label: "Java" },
  { value: "python", label: "Python" },
  { value: "machine_learning", label: "Machine Learning" },
  { value: "react_js", label: "React JS" },
  { value: "node_js", label: "Node JS" },
  { value: "react_native", label: "React Native" },
];

export default function RegistrationForm() {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      await addDoc(collection(db, "users"), values);
      swal({
        title: "Form Submitted",
        text: "Our team will contact you shortly.",
        icon: "success",
        dangerMode: false,
        content: {
          element: "div",
          attributes: {
            innerHTML: `
              <p>Click the button below to join our WhatsApp group:</p>
              <a href="https://chat.whatsapp.com/F1zZv6oKJn51N0Y7oCPzJb" 
                 target="_blank" 
                 style="display: inline-block; padding: 10px 20px; background-color: #25D366; color: white; text-decoration: none; border-radius: 5px; font-weight: bold; margin-top: 10px;">
                Join WhatsApp Group
              </a>
            `,
          },
        },
      });

      resetForm();
      const emailParams = {
        // to_email: "apnicoding72@gmail.com",
        to_email: "vikasshukla239@gmail.com",
        first_name: values?.first_name,
        middle_name: values?.middle_name,
        last_name: values?.last_name,
        birth_date: values?.birth_date,
        gender: values?.gender,
        email: values?.email,
        mobile: values?.mobile,
        course: values?.course,
        is_working_professional: values?.is_working_professional,
        company_name: values?.company_name,
        subject: "New Form Submission",
        message: `A new user has submitted the form. With reason ${
          values?.training_reason || "NA"
        } `,
      };

      await emailJs.send(
        "service_0kxge9o", // service id
        "template_qir6ao8", // template id
        emailParams,
        "rJC1lVqJ44heTDla5" // public key
      );
    } catch (error) {
      console.log("error ", error);
      showToast("Error saving data.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mt-4 p-4">
      <h2 className="text-center">Registration Form</h2>
      {/* <p className="text-center text-muted">
        Fill out the form carefully for registration
      </p> */}

      <hr />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Personal Details Section */}
            <Card className="custom-card">
              <CardHeader className="card-header">Personal Details</CardHeader>
              <CardBody className="card-body">
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="first_name"
                        className="form-control custom-input-box"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^A-Za-z\s]/g,
                            ""
                          ))
                        }
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Middle Name</Label>
                      <Field
                        name="middle_name"
                        className="form-control custom-input-box"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^A-Za-z\s]/g,
                            ""
                          ))
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Last Name<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="last_name"
                        className="form-control custom-input-box"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^A-Za-z\s]/g,
                            ""
                          ))
                        }
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Email<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="email"
                        type="email"
                        className="form-control custom-input-box"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Mobile Number<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="mobile"
                        className="form-control custom-input-box"
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9]/g, "")
                            .slice(0, 10);
                        }}
                      />
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Birth Date<span className="text-danger">*</span>
                      </Label>
                      <CustomDatePicker
                        value={values.birth_date}
                        onChange={(date) => setFieldValue("birth_date", date)}
                        minDate={null} // No min date restriction
                        maxDate={new Date()} // Sets max date to today
                        placeholder="Select Birth Date"
                      />

                      <ErrorMessage
                        name="birth_date"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Gender<span className="text-danger">*</span>
                      </Label>
                      <CustomSelect
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                          { value: "other", label: "Other" },
                        ]}
                        value={values.gender}
                        onChange={(value) => setFieldValue("gender", value)}
                        placeholder="Select Gender"
                      />
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Are you a Working Professional?</Label>
                      <div className="d-flex flex-column-reverse flex-lg-row gap-2">
                        <Button
                          className={`working-btn ${
                            !values.is_working_professional ? "active-btn" : ""
                          }`}
                          onClick={() =>
                            setFieldValue("is_working_professional", false)
                          }
                        >
                          Student
                        </Button>
                        <Button
                          className={`working-btn ${
                            values.is_working_professional ? "active-btn" : ""
                          }`}
                          onClick={() =>
                            setFieldValue("is_working_professional", true)
                          }
                        >
                          Working Professional
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                  {values.is_working_professional ? (
                    <Col md={4}>
                      <FormGroup>
                        <Label>
                          Company Name<span className="text-danger">*</span>
                        </Label>
                        <Field
                          name="company_name"
                          className="form-control custom-input-box"
                        />
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col md={4}>
                      <FormGroup>
                        <Label>College Name</Label>
                        <Field
                          name="college_name"
                          className="form-control custom-input-box"
                        />
                        <ErrorMessage
                          name="college_name"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>

            <Card className="custom-card mt-4">
              <CardHeader className="card-header">Course Details</CardHeader>
              <CardBody className="card-body">
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Select Course<span className="text-danger">*</span>
                      </Label>
                      <CustomSelect
                        options={courseList}
                        value={values.course}
                        onChange={(value) => setFieldValue("course", value)}
                        placeholder="Select Course"
                      />
                      <ErrorMessage
                        name="course"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Experience Level<span className="text-danger">*</span>
                      </Label>
                      <CustomSelect
                        options={[
                          { value: "0-1", label: "0-1 Years" },
                          { value: "1-3", label: "1-3 Years" },
                          { value: "3+", label: "3+ Years" },
                        ]}
                        value={values.experience_level}
                        onChange={(value) =>
                          setFieldValue("experience_level", value)
                        }
                        placeholder="Select Experience Level"
                      />
                      <ErrorMessage
                        name="experience_level"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Select Education Background
                        <span className="text-danger">*</span>
                      </Label>
                      <CustomSelect
                        options={[
                          // { value: "highschool", label: "High School" },
                          // {
                          //   value: "associatedegree",
                          //   label: "Associate Degree",
                          // },
                          { value: "bachelor", label: "Bachelor's Degree" },
                          { value: "master", label: "Master's Degree" },
                          // { value: "phd", label: "PhD" },
                        ]}
                        value={values.education_background}
                        onChange={(value) =>
                          setFieldValue("education_background", value)
                        }
                        placeholder="Select Education Background"
                      />
                      <ErrorMessage
                        name="education_background"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="custom-card mt-4">
              <CardHeader className="card-header">Other Details</CardHeader>
              <CardBody className="card-body">
                <Row>
                  {/* <Col md={4}>
                    <FormGroup>
                      <Label>Resume</Label>
                      <input
                        name="resume"
                        type="file"
                        className="form-control custom-input-box"
                        onChange={(event) =>
                          setFieldValue("resume", event.currentTarget.files[0])
                        }
                      />
                      <ErrorMessage
                        name="resume"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col> */}

                  <Col md={12}>
                    <FormGroup>
                      <Label>Why do you want to join this training?</Label>
                      <Field
                        name="training_reason"
                        className="form-control custom-input-box"
                        as="textarea"
                        rows={2}
                        style={{ minHeight: "150px" }}
                      />
                      <ErrorMessage
                        name="training_reason"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                style={{ minWidth: "240px", backgroundColor: "#055177" }}
                className="mt-3"
                size="lg"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Submit"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
