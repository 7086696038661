import React, { useRef } from "react";
import DatePicker from "react-multi-date-picker";
import { AiOutlineCalendar } from "react-icons/ai";
import "./customDatePicker.scss";

export default function CustomDatePicker({
  value,
  onChange,
  placeholder,
  minDate,
  maxDate,
}) {
  const datePickerRef = useRef();

  return (
    <div className="custom-date-picker">
      <div className="date-picker-wrapper">
        <DatePicker
          ref={datePickerRef}
          value={value || ""}
          onChange={(date) => onChange(date?.toDate?.() || date)}
          format="DD/MM/YYYY"
          placeholder={placeholder || "Select Date"}
          minDate={minDate}
          maxDate={maxDate}
          className="styled-datepicker"
          containerClassName="datepicker-container"
          portal={true} // ✅ Ensures the calendar is rendered outside the card
          position="bottom" // ✅ Ensures it opens below the input field
        />
        <AiOutlineCalendar
          className="calendar-icon"
          onClick={() => datePickerRef.current.openCalendar()} 
        />
      </div>
    </div>
  );
}
