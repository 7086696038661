import React, { useEffect } from "react";
import Job from "../job/Job";
import Explore from "../../pages/explore/Explore";
import AboutUs from "../../pages/aboutUs/AboutUs";
import AboutGallery from "../../pages/aboutUs/AboutGallery";
import Search from "../search/Search";
import ClientTestimonial from "../testimonial/ClientTestimonial";
import HeaderCarousel from "../../pages/hero/Hero";
import JobDetails from "../../shared-components/jobDetails/JobDetails";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../redux/slices/user/userSlice";
import {
  fetchJobCategories,
  fetchJobList,
} from "../../redux/slices/job/jobSlice";
import Service from "../service/Service";
import HomeService from "../service/HomeService";

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchJobCategories());
    // dispatch(fetchJobList())
  }, [dispatch]);
  
  return (
    <>
      <HeaderCarousel />
      <div class="container-xxl bg-white p-0">
        {/* <Search /> */}
        <HomeService />
        <AboutGallery />
        <Explore />
        <Job />
        {/* <ClientTestimonial /> */}
      </div>
    </>
  );
}

export default Home;
