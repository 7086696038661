import React from 'react';
import AboutGallery from './AboutGallery';

export default function AboutUs() {
    return (
        <>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="mb-4">MV Hiring: Connecting Talent with Opportunity</h1>
                <p className="mb-4">
                    MV Hiring is a premier recruitment and HR solutions agency, dedicated to helping businesses thrive by connecting them with the right talent. We understand that people are the cornerstone of every successful organization, and our mission is to ensure that you have the human capital you need to achieve your business goals.
                </p>
                <p className="mb-4">
                    At MV Hiring, we provide a comprehensive range of services designed to address every aspect of your HR and recruitment needs:
                </p>
                <ul className="mb-4">
                    <li><strong>Recruitment & Talent Acquisition:</strong> Identifying top-tier candidates who not only possess the right skills but also align with your company’s culture and values.</li>
                    <li><strong>Employee Training & Development:</strong> Tailored programs that equip your team with the tools to grow, perform, and contribute to overall success.</li>
                    <li><strong>KPI Development & Performance Management:</strong> Implementing measurable metrics to ensure your team is aligned with business objectives.</li>
                    <li><strong>Employee Engagement Programs:</strong> Initiatives that foster a motivated, high-performing workforce to enhance productivity and retention.</li>
                    <li><strong>HR Policy Development:</strong> Assisting in creating compliant HR policies that streamline operations and safeguard your business.</li>
                </ul>
                <p className="mb-4">
                    With extensive industry expertise and a commitment to delivering customized solutions, MV Hiring acts as a strategic partner for businesses looking to enhance their workforce and achieve sustainable growth. We tailor our services to your organization’s goals and culture.
                </p>
                <p className="mb-4">
                    Whether you’re looking to build a high-performing team, develop your current workforce, or optimize HR processes, MV Hiring provides the expertise and support necessary for success. We focus on long-term partnerships, ensuring you build a strong, dynamic workforce that drives your business forward.
                </p>
                <p>
                    Choose MV Hiring for a seamless, efficient, and professional recruitment experience. Let us help you find the talent that will shape your future success.
                </p>
            </div>
        </>
    );
}
