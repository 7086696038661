export const ERROR_MESSAGE = {
    SOMETHING_WENT_WRONG:"Something went wrong",
    COURSElIST_NOT_FOUND: "Unable to find course list",
    LOGIN_FAILED: "Unable to login",
    UPDATE_PROFILE_FAILED:"Unable to update profile",
    FETCH_PROFILE_FAILED:"Unable to fetch data",
    FETCH_JOB_CATEGORIES_FAILED: "Unable to fetch data",
    FETCH_JOB_LIST_FAILED:"Unable to fetch data",
    FETCH_JOB_BY_ID_FAILED:"Unable to fetch data",
    CREATE_JOB_FAILED:"Unable to create post",
    CREATE_JOB_CATEGORIES_FAILED:"Unable to create job categories",
    FETCH_COMPANY_LIST_FAILED:"Unable to fetch company list",
    CREATE_CONTACT_FAILED:"Unable to create contact use",
    JOB_APPLY_FAILED:"Unable to apply job",
    FETCH_APPLIED_JOBS_FAILED:"Unable to fetch applied job"
};

export const CONSTANT_NAME = {
   EMAIL_ID: 'talent@mvhiring.com',
   MOBILE_NUMBER: '+918010318673',
   ADDRESS:'S No 8/1C New Baner Sus Smart City, road, Baner, Pune, Maharashtra 411045',
   
};

export const urlMappings = {
    S3_BASE_URL: '',
    FACEBOOK_URL: 'https://www.facebook.com/profile.php?id=61565068107807&mibextid=JRoKGi',
    INSTAGRAM_URL: 'https://www.instagram.com/mvhiring?utm_source=qr&igsh=MWhvcm5rcGptbXZ2ag==',
    TWITTER_URL:'',
    YOUTUBE_URL:'https://www.youtube.com/@apni.coding',
    GIT_HUB_URL:'',
    LINKED_IN: 'https://www.linkedin.com/company/mv-hiring/',
    YOUTUBE_VIDEO_URL:'https://www.youtube.com/@apni.coding'
}