import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Import icon
import { useNavigate } from "react-router-dom";

export default function ServiceCard({ title, image, description }) {
  const [isReadMore, setIsReadMore] = useState(false);
  const Navigate = useNavigate(); // For navigation in Next.js

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const cardDescription = isReadMore
    ? description
    : `${description.slice(0, 500)}`;

  const handleEnquiryClick = () => {
    // Navigate to Contact Us form
    Navigate("/contact");
  };

  return (
    <Card sx={{ maxWidth: 380, mt: 4, borderRadius: "15px" }}>
      <CardMedia component="img" height="175" image={image} alt={title} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{ color: "#225683", fontWeight: 800 }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textAlign={"justify"}
          gutterBottom
        >
          {cardDescription}
        </Typography>
        {/* <Button size="small" onClick={toggleReadMore}>
          {isReadMore ? "Read Less" : "Read More"}
        </Button> */}
        {title === "Employee Training" ? (
          <Button
            // variant="contained"
            endIcon={<ArrowForwardIcon />} // Icon for the button
            sx={{ mt: 2 }}
            onClick={()=> Navigate("/registration")}
          >
            Register Now
          </Button>
        ) : (
          <Button
            // variant="contained"
            endIcon={<ArrowForwardIcon />} // Icon for the button
            sx={{ mt: 2 }}
            onClick={handleEnquiryClick}
          >
            BOOK ENQUIRY
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
