import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzMK7Adng8Rj6HGZSuhQYtd3C7YO7N4jA",
  authDomain: "regsitration-3e0ca.firebaseapp.com",
  projectId: "regsitration-3e0ca",
  storageBucket: "regsitration-3e0ca.firebasestorage.app",
  messagingSenderId: "406108728951",
  appId: "1:406108728951:web:f02fc811641712630fa072"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc, getDocs };
