import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Loadable from "./shared-components/lazyLoading/Loadable";
import Navbar from "./components/navbar/Navbar";
import Explore from "./pages/explore/Explore";
import AboutGallery from "./pages/aboutUs/AboutGallery";
import Job from "./components/job/Job";
import Home from "./components/home/Home";
import Footer from "./pages/footer/Footer";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ProtectedRoute from "./ProtectedRoutes";
import ProfileCompelete from "./components/profileComplete/ProfileCompelete";
import ContactUs from "./pages/contactUs/ContactUs";
import MainLayout from "./admin/MainLayout";
import JobDetails from "./shared-components/jobDetails/JobDetails";
import AllJob from "./components/job/component/AllJob";
import JobByCateogries from "./components/job/component/JobByCateogries";
import ComingSoon from "./shared-components/comingSoon/ComingSoon";
import AppliedJobsTable from "./components/appliedJobs/AppliedJobs";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import WhatsAppButton from "./shared-components/whatsAppButton/WhatsAppButton";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/termsandconditions/Terms&Condidtions";
import Service from "./components/service/Service";
import RegistrationForm from "./components/registrationForm/RegistrationForm";

const LightMode = Loadable(() => import("./shared-components/LightMode/LightMode"));

function App() {
  const location = useLocation(); 
  const authRoutes = ["/register", "/login", "/forgot-password", "/reset-password"]; 
  const adminRoutes = ["/admin", "/admin/add-job", "/admin/add-job-category", ];

  // Check if the current path is an auth or admin route
  const isAuthRoute = authRoutes.includes(location.pathname);
  const isAdminRoute = adminRoutes.some(route => location.pathname.startsWith(route));

  return (
    <>
      {/* Render Navbar and Footer only if not on an auth or admin route */}
      {!isAuthRoute && !isAdminRoute && <Navbar />}
     <WhatsAppButton />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path="/profile-complete" element={<ProfileCompelete />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/about" element={<AboutGallery />} />
        <Route path="/job" element={<Job />} />
        <Route path="/job/:id" element={<JobDetails />} />
        <Route path="/job-list" element={<AllJob />} />
        <Route path="/job-category/:category" element={<JobByCateogries />} />
        <Route path="/services" element={<ComingSoon />} />
        <Route path="/applied-jobs" element={<AppliedJobsTable />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/our-service" element={<Service />} />
        <Route path="/registration" element={<RegistrationForm />} />








        {/* <Route
          path="/about"
          element={<ProtectedRoute element={<AboutGallery />} roles={["admin", "candidate", "client"]} />}
        /> */}


        {/* Admin routes - nested under MainLayout */}
        
        <Route
          path="/admin/*"
          element={<ProtectedRoute element={<MainLayout />} roles={["admin"]} />}
        />
       
      </Routes>
      
      {/* Render Footer only if not on an auth or admin route */}
      {!isAuthRoute && !isAdminRoute && <Footer />}
    </>
  );
}

export default function AppWrapper() {
  return (
    <Router >
      <App />
    </Router>
  );
}
