import React from "react";
import Select from "react-select";
import { AiOutlineDown } from "react-icons/ai"; // Custom dropdown icon
import "./customSelect.scss";

export default function CustomSelect({
  options = [],
  value,
  onChange,
  placeholder = "Select an option",
  isDisabled = false,
  isSearchable = true,
  menuPlacement = "auto", // Automatically adjust dropdown placement
}) {
  return (
    <div className="custom-select">
      <Select
        options={options}
        value={options.find((opt) => opt.value === value) || null} // Ensures correct selection
        onChange={(selectedOption) =>
          onChange(selectedOption ? selectedOption.value : null)
        }
        placeholder={placeholder}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body} // ✅ Fixes dropdown clipping inside a card
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }), // ✅ Ensures it appears on top
        }}
        components={{
          DropdownIndicator: () => <AiOutlineDown className="dropdown-icon" />,
        }} // Custom icon
        classNamePrefix="react-select"
      />
    </div>
  );
}
