import React, { useEffect, useState } from "react";
import { collection, getDocs, db } from "../../config/firebase";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

export default function CourseList() {
  const [courses, setCourses] = useState([]);
  const [filter, setFilter] = useState("all"); // Track filter state

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const courseList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCourses(courseList);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const filteredCourses =
    filter === "all" ? courses : courses.filter((course) => course.course === filter);

  return (
    <div className="container-fluid mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Course Application List</h1>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Middle Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>College Name</TableCell>
              <TableCell>Education Background</TableCell>
              <TableCell>Experience Level</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Training Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCourses.map((course) => (
              <TableRow key={course?.id}>
                <TableCell>{course?.first_name}</TableCell>
                <TableCell>{course?.middle_name}</TableCell>
                <TableCell>{course?.last_name}</TableCell>
                <TableCell>{course?.gender}</TableCell>
                <TableCell>{course?.email}</TableCell>
                <TableCell>{course?.mobile}</TableCell>
                <TableCell>{course?.company_name}</TableCell>
                <TableCell>{course?.college_name}</TableCell>
                <TableCell>{course?.education_background}</TableCell>
                <TableCell>{course?.experience_level}</TableCell>
                <TableCell>{course?.course}</TableCell>
                <TableCell>{course?.training_reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
